require('./bootstrap');



import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true
});


import Cookies from 'js-cookie';
window.CookiesManager = Cookies;

window.bootstrap_select = require("bootstrap-select")
window.ion_rangeslider = require("ion-rangeslider")
window.bootstrap_toggle = require("bootstrap-toggle")
window.bootstrap_table = require("bootstrap-table")

require("jquery-titlealert/jquery.titlealert")
require("blueimp-file-upload/js/jquery.fileupload")

window.noUiSlider = require("nouislider/dist/nouislider.min")

require("isotope-layout/dist/isotope.pkgd.min.js")
require('isotope-masonry-horizontal/masonry-horizontal.js')

import 'keen-slider/keen-slider.min.css';

import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'jquery-ui/ui/widgets/draggable.js';

window.readUrl = function(input, $target) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    
    reader.onload = function(e) {
      $target.attr('src', e.target.result);
    }
    
    reader.readAsDataURL(input.files[0]); // convert to base64 string
  }
}

window.imageZoom = function (imgID, resultID) {
  var img, lens, result, cx, cy;
  img = document.getElementById(imgID);
  result = document.getElementById(resultID);
  /* Create lens: */
  lens = document.createElement("DIV");
  lens.setAttribute("class", "img-zoom-lens");
  /* Insert lens: */
  img.parentElement.insertBefore(lens, img);
  /* Calculate the ratio between result DIV and lens: */
  cx = result.offsetWidth / lens.offsetWidth;
  cy = result.offsetHeight / lens.offsetHeight;
  /* Set background properties for the result DIV */
  result.style.backgroundImage = "url('" + img.src + "')";
  result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
  /* Execute a function when someone moves the cursor over the image, or the lens: */
  lens.addEventListener("mousemove", moveLens);
  img.addEventListener("mousemove", moveLens);
  /* And also for touch screens: */
  lens.addEventListener("touchmove", moveLens);
  img.addEventListener("touchmove", moveLens);
  
  function moveLens(e) {
    var pos, x, y;
    /* Prevent any other actions that may occur when moving over the image */
    e.preventDefault();
    /* Get the cursor's x and y positions: */
    pos = getCursorPos(e);
    /* Calculate the position of the lens: */
    x = pos.x - (lens.offsetWidth / 2);
    y = pos.y - (lens.offsetHeight / 2);
    /* Prevent the lens from being positioned outside the image: */
    if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
    if (x < 0) {x = 0;}
    if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
    if (y < 0) {y = 0;}
    /* Set the position of the lens: */
    lens.style.left = x + "px";
    lens.style.top = y + "px";
    /* Display what the lens "sees": */
    result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
  }
  function getCursorPos(e) {
    var a, x = 0, y = 0;
    e = e || window.event;
    /* Get the x and y positions of the image: */
    a = img.getBoundingClientRect();
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /* Consider any page scrolling: */
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return {x : x, y : y};
  }
}

window.isSmartPhone = function() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

function normalizeSlideHeights() {
    $('.carousel-normalize-height').each(function(){
      var items = $('.carousel-item', this);
      // reset the height
      items.css('min-height', 0);
      // set the height
      var maxHeight = Math.max.apply(null, 
          items.map(function(){
              return $(this).outerHeight()}).get() );
      items.css('min-height', maxHeight + 'px');
    })
}

$(window).on(
    'load resize orientationchange', 
    normalizeSlideHeights
);

window.bootsram_sm_px = 576;
window.bootsram_md_px = 768;
window.bootsram_lg_px = 992;
window.bootsram_xl_px = 1200;

$(document).ready(function(){

    $(".delayed_selectpicker").selectpicker();


    $(".multi-carousel-container").mousemove(function() {

      var parentOffset = $(this).parent().offset(); 

      var relX = event.pageX - parentOffset.left;
      var mouse_x_percent = relX / $(this).width();

      var total_scroll_dist = this.scrollWidth - this.clientWidth;
      
      var scroll = total_scroll_dist * mouse_x_percent;

      $(this).scrollLeft(scroll);

    })


    $("div[class^='potato-']").on("swipe",function(event) {

      var parentOffset = $(this).parent().offset(); 

      var relX = event.pageX - parentOffset.left;
      var mouse_x_percent = relX / $(this).width();

      var total_scroll_dist = this.scrollWidth - this.clientWidth;
      
      var scroll = total_scroll_dist * mouse_x_percent;

      $(this).scrollLeft(scroll);

    })


}); 